import { deviceElectroCategory } from "components/shop/const";
import {
  ROUTE_ASKUE_INTEGRATION,
  ROUTE_ASKUE_PAGE,
  ROUTE_SHOP,
} from "stream-constants/route-constants";

export const promoQuery = `?categorys=${deviceElectroCategory}&conn_types=NB-IoT,GSM/GPRS`;

export const title = "АСКУЭ на промышленных предприятиях: цели и преимущества в производстве";

export const text = {
  par_1: {
    id: "1",
    title: "Что такое АСКУЭ на предприятии?",
    blocks: [
      {
        text: [
          "В статье постараемся разобраться с вопросом: «Что такое АСКУЭ на предприятии?».",
          "АСКУЭ (или АИИСКУЭ) - автоматизированная система коммерческого учета электроэнергии (мощности).",
          "АСКУЭ является одним из инструментов повышения эффективности производительности и улучшения экономических показателей производственной деятельности промышленного предприятия.",
          "Система позволяет организовать коммерческий учет электроэнергии для осуществления финансовых расчетов поставщиков электроэнергии с конечными потребителями, а для промышленных предприятий - это дополнительная возможность внедрить технический учет (для оценки структуры расхода и качества электроэнергии в каждом помещении, цеху, на отдельной производственной линии и т.д.).",
          "Решение состоит из аппаратной (счетчики электроэнергии, сервера для хранения данных) и программной части (программное обеспечение для опроса и сбора данных с каждого прибора учета на производстве), также нужен канал связи для обмена информацией между устройствами внутри системы.",
          `Результатом создания <a href="/${ROUTE_ASKUE_PAGE}" target="_blank">АСКУЭ</a> на производстве станет возможность контролировать потребление энергоресурсов, оптимизировать их расход, повысить энергоэффективность и выбрать наиболее подходящую под профиль деятельности промышленного предприятия ценовую категорию на электроэнергию.`,
          "Внедрению систем АСКУЭ способствовало принятие Федерального закона №522, регламентирующего развитие систем интеллектуального учета энергоресурсов.",
          "Требования к самим система АСКУЭ и минимальному набору функций регламентируются Постановлением Правительства РФ № 890.",
        ],
      },
    ],
  },
  par_2: {
    id: "2",
    title: "Преимущества от внедрения АСКУЭ на предприятии",
    blocks: [
      {
        text: [
          "При относительно низкой стоимости внедрения системы интеллектуального учета электроэнергии на предприятии, организация получает ряд неоспоримых выгод. Некоторыми из них являются:",
          [
            "Точность и прозрачность взаиморасчетов с поставщиками электроэнергии;",
            "Возможность автоматического сбора данных о потреблении электроэнергии по всей распределенной сети объектов промышленного предприятия или торговой сети;",
            "Организация почасового, а при необходимости и более частого, учета электроэнергии, формирование архивов данных. Периодичность опроса приборов учета может настраиваться в зависимости от нужд персонала организации;",
            "Анализ архивов данных о потреблении позволит выбрать наиболее выгодную ценовую категорию для покупки электроэнергии;",
            "Контроль разрешенной максимальной мощности и качества электроэнергии;",
            "Возможность получать информацию об аварийных событиях и прогнозировать их наступление;",
            "Графическое отображение данных о расходе ресурсов предприятия.",
          ],
        ],
      },
    ],
  },
  par_3: {
    id: "3",
    title: "Как внедрить АСКУЭ на промышленном предприятии:",
    blocks: [
      {
        text: [
          "Для внедрения АСКУЭ потребуется определиться с моделью счетчиков и способом передачи данных с приборов учета на платформу верхнего уровня.",
          "Важным моментом является выбор программного обеспечения для организации опроса приборов учета и модели его использования. ",
          "Предприятие может приобрести у разработчика лицензию на использование ПО (программное обеспечение), или же, воспользоваться сервисной моделью работы.",
          "Если пойти по первому пути и приобрести лицензию, то потребуются значительные затраты на первоначальном этапе (покупка лицензии, серверов для опроса и хранения данных, и пр.), а также расходы на квалифицированный персонал для поддержания работоспособности системы.",
          "При выборе варианта работы по сервисной модели, можно воспользоваться облачным сервисом у поставщика услуги и избежать первоначальных расходов. В данном случае вся инфраструктура находится на стороне поставщика АСКУЭ и поддержание её работоспособности находится в зоне ответственности компании провайдера. При этой схеме работы пользователь только определяет какие счетчики он хочет подключить к сервису и рассчитывается только за фактически подключенные к сервису устройства.",
          "Что касается способов передачи данных с приборов учета в программный комплекс, то есть возможность выбрать из различных технологий связи: GSM/GPRS, LoRaWan, NB-IoT, PLC, Ethernet, ZigBee, RF. Каждая из них имеет свои преимущества и недостатки.",
        ],
      },
    ],
  },
  par_4: {
    id: "4",
    title: "Предложение «МТС» по внедрению АСКУЭ",
    blocks: [
      {
        text: [
          `«МТС» предлагает воспользоваться сервисом <a href="/${ROUTE_ASKUE_PAGE}" target="_blank">АСКУЭ</a>, как облачным, по подписке. Само программное обеспечение размещено в ЦОД компании «МТС», компания берет на себя затраты по поддержанию работоспособности оборудования и функционированию программы, хранит и обеспечивает безопасность данных архивов показаний со счетчиков.`,
          "Решение полностью соответствует требованиям Постановления Правительства РФ №890. Для подключения к сервису нет необходимости закупать оборудование или программное обеспечение. Потребуется только сообщить о намерении подключиться к сервису АСКУЭ и уточнить модели приборов учета, планируемых к подключению.",
        ],
      },
    ],
  },
  par_6: {
    id: "6",
    title: "Цена предоставления облачного сервиса АСКУЭ и преимущества работы с «МТС»",
    blocks: [
      {
        text: [
          `<a href="/${ROUTE_ASKUE_INTEGRATION}" target="_blank">Стоимость</a> на услуги «МТС» по предоставлению сервиса АСКУЭ прозрачна, отсутствуют дополнительные платежи.`,
          "Расчет ведется за фактическое количество приборов учета на платформе, ежемесячными платежами.",
          "Специалисты «МТС» помогают заказчикам с заведением приборов учета на платформу, формированием отчетов для ресурсоснабжающих компаний. Консультируют по вопросам полноты сбора данных, технической поддержки, мониторинга выхода приборов учета на связь. Тем самым, выполняя часть функционала операторов автоматизированной системы учета на стороне заказчика.",
          "Сбор данных организован по GSM/GPRS (TCP/IP) или NB-IoT (UDP) сети.",
          "Для передачи данных используются защищенные каналы связи, выделенный APN уже включен в стоимость услуги и не требуется дополнительной оплаты.",
          "Достоинствами APN (Access Point Name) являются:",
          [
            "Безопасность удаленной работы с оборудованием и базами данных;",
            "Обеспечение конфиденциальности информации;",
            "Защищенная передача данных между устройствами.",
          ],
          "При использовании «МТС» в качестве оператора мобильной связи для передачи данных со счетчиков на платформу учета, у организации отсутствуют дополнительные расходы на связь и передачу данных.",
          `Помимо самого сервиса АСКУЭ, в <a href="/${ROUTE_SHOP}" target="_blank">каталоге оборудования</a> представлены приборы учета различных заводов-производителей.`,
        ],
      },
    ],
  },
  par_7: {
    id: "7",
    title: "Как подключить АСКУЭ на предприятии",
    blocks: [
      {
        text: [
          "Для того чтобы воспользоваться предложением АСКУЭ от МТС, необходимо уточнить полное наименование модели установленных или планируемых к установке приборов учета. Специалисты «МТС» оценят возможность подключения счетчиков к сервису и помогут выбрать наиболее предпочтительный способ передачи данных.",
          `Заявку на подключение к автоматизированной системе учета электроэнергии на предприятии можно сделать <a href="/${ROUTE_ASKUE_PAGE}" target="_blank">здесь</a>.`,
          "При необходимости может быть предоставлен бесплатный тестовый доступ к сервису, чтобы сотрудники компании-заказчика смогли оценить качество и удобство использования услуги.",
        ],
      },
    ],
    banner: true,
  },
};

export const shopText =
  "В нашем магазине вы можете приобрести оборудование поддерживающее АСКУЭ с возможностью передачи данных по сети GSM/GPRS или NB-IoT.";

export const seo = {
  title:
    "АСКУЭ на предприятии: для чего нужна, какая выгода от внедрения и сколько стоит подключение?",
  description: "",
};
